import React from "react"
import styled from "styled-components"
import { tablet } from "../util/mediaQueries"

const RootDiv = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
`

interface Props {
  children: React.ReactNode
}

const HeaderDiv = (props: Props) => (
  <RootDiv>
    {props.children}
  </RootDiv>
)

export default HeaderDiv
