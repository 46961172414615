import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import styled from "styled-components"
import { BuchhaltungIconScalable } from "../icons/buchhaltungIcon"
import { SteuerberatungIconScalable } from "../icons/steuerberatunIcon"
import { UnternehmensberatungIconScalable } from "../icons/unternehmensberatungIcon"
import HeaderDiv from "./headerDiv"
import Text, { TextType } from "./text"
import { laptop } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const ServicesRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const Line = styled.div`
  height: 0px;
  border-top: 1px solid black;
  width: 100%;
  margin-bottom: 10px;
`

const ServiceDiv = styled(AnchorLink)`
  text-decoration: none;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.textColor};
  font-size: ${props => props.theme.subHeaderSize};
  border-radius: 10px;
  max-width: 550px;
  margin-left: 20px;
  margin-right: 20px;
  @media ${laptop} {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
    max-width: none;
  }
  margin-top: 30px;
  margin-bottom: 30px;
  .icon {
    position: absolute;
    height: 100px;
    width: 100px;
    transition: all 0.2s ease-in-out;
  }
  @media ${laptop} {
    &:hover {
      .icon {
        transform: scale(1.2);
      }
    }
  }
`

const IconDiv = styled.div`
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
`

const ServiceTitleTextDiv = styled.div`
  white-space: pre-line;
  text-align: center;
  margin-bottom: 20px;
`

const ServiceTitleText = ({ children }: { children: React.ReactNode }) => (
  <ServiceTitleTextDiv>
    <Text type={TextType.SubHeader}>{children}</Text>
  </ServiceTitleTextDiv>
)

const SteuerberatungService = () => (
  <ServiceDiv
    to="/leistungen#steuerberatung"
    gatsbyLinkProps={{
      state: { openIndex: 0 },
    }}
  >
    <IconDiv>
      <SteuerberatungIconScalable className="icon" width="auto" height="auto" />
    </IconDiv>
    <Media greaterThanOrEqual="lg">
      <ServiceTitleText>{`Steuerberatung & \n Jahresabschluss`}</ServiceTitleText>
    </Media>
    <Media lessThan="lg">
      <ServiceTitleText>{`Steuerberatung & Jahresabschluss`}</ServiceTitleText>
    </Media>
    <Line />
    <Text type={TextType.Body}>
      „Steuerbelastung senken oder wirtschaftliche Stärke zeigen?“ Hier ist
      Fingerspitzengefühl gefragt.
    </Text>
  </ServiceDiv>
)

const UnternehmensberatungService = () => (
  <ServiceDiv
    to="/leistungen#unternehmensberatung"
    gatsbyLinkProps={{
      state: { openIndex: 1 },
    }}
  >
    <IconDiv>
      <UnternehmensberatungIconScalable className="icon" />
    </IconDiv>
    <Media greaterThanOrEqual="lg">
      <ServiceTitleText>{`Unternehmens- & \n Gründungsberatung`}</ServiceTitleText>
    </Media>
    <Media lessThan="lg">
      <ServiceTitleText>{`Unternehmens- & Gründungsberatung`}</ServiceTitleText>
    </Media>
    <Line />
    <Text type={TextType.Body}>
      Erfolgreich werden, erfolgreich bleiben! Mit unserer individuellen
      Unternehmensberatung.
    </Text>
  </ServiceDiv>
)

const BuchhaltungService = () => (
  <ServiceDiv
    to="/leistungen#buchhaltung"
    gatsbyLinkProps={{
      state: { openIndex: 2 },
    }}
  >
    <IconDiv>
      <BuchhaltungIconScalable className="icon" />
    </IconDiv>
    <Media greaterThanOrEqual="lg">
      <ServiceTitleText>{`Finanz- & \n Lohnbuchhaltung`}</ServiceTitleText>
    </Media>
    <Media lessThan="lg">
      <ServiceTitleText>{`Finanz- & Lohnbuchhaltung`}</ServiceTitleText>
    </Media>
    <Line />
    <Text type={TextType.Body}>
      Unterschiedliche Unternehmen brauchen verschiedene Lösungen.
    </Text>
  </ServiceDiv>
)

const ServicesIndex = () => {
  return (
    <RootDiv>
      <HeaderDiv>
        <Text type={TextType.Header}>Unsere Leistungen</Text>
      </HeaderDiv>
      <ServicesRow>
        <SteuerberatungService />
        <UnternehmensberatungService />
        <BuchhaltungService />
      </ServicesRow>
    </RootDiv>
  )
}

export default ServicesIndex
