import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import getDummyText from "../dummyText"
import HeaderDiv from "./headerDiv"
import Text, { TextType } from "./text"
import { laptop } from "../util/mediaQueries"
import { Helmet } from "react-helmet"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const SampleImage = () => (
  <StaticImage
    src="../images/client.png"
    alt={"Bla"}
    formats={["AUTO", "WEBP", "AVIF"]}
    imgStyle={{ objectFit: "fill" }}
    style={{ height: "100%", width: "100%", borderRadius: "10px 10px 0px 0px" }}
  />
)

// Position relative in order to make it work in safari
const BoxDiv = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: ${props => props.theme.shadow};
  &:hover {
    box-shadow: ${props => props.theme.shadowHovered};
  }
  transition: box-shadow 0.2s;
  background: ${props => props.theme.second};
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 550px;
  @media ${laptop} {
    width: 30%;
    margin: none;
    max-width: none;
  }
`

const ImgDiv = styled.div`
  border-radius: 10px 10px 0px 0px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 200px;
`

const TextDiv = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const BoxHeaderTextDiv = styled.p`
  margin-bottom: 10px;
  margin-top: 10px;
`

interface BoxProps {
  header: string
}

const Box = (props: BoxProps) => (
  <BoxDiv>
    <ImgDiv>
      <SampleImage />
    </ImgDiv>
    <TextDiv>
      <BoxHeaderTextDiv>
        <Text type={TextType.SubHeader}>{props.header}</Text>
      </BoxHeaderTextDiv>
      <Text type={TextType.Body}>{getDummyText(150)}</Text>
    </TextDiv>
  </BoxDiv>
)

const SteuernewsIndex = () => {
  return (
    <>
      <HeaderDiv>
        <Text type={TextType.Header}>Steuernews</Text>
      </HeaderDiv>
      <Helmet>
        <script type="text/javascript">var K='107802'</script>
        <script src="https://107802.mainfo.net/teaser.js" defer type="text/javascript" />
      </Helmet>

      <div id="myContent"></div>
    </>

  )
}
export default SteuernewsIndex
