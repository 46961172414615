import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FirstSection from "../components/firstSection"
import ContentDiv from "../components/contentDiv"
import ServicesIndex from "../components/servicesIndex"
import PageSection from "../components/pageSection"
import SteuernewsIndex from "../components/steuernewsIndex"
import PageImage from "../components/pageImage"
import { Media } from "gatsby-plugin-fresnel"

const IndexPage = () => {
  return (
    <Layout>
      <SEO lang="de" title="Home" />
      <PageImage>
        <Media greaterThanOrEqual="md">
          <StaticImage
            src="../images/Titelbild_Home_PC.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
        <Media at="sm">
          <StaticImage
            src="../images/Titelbild_Home_Smartp.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
      </PageImage>
      <FirstSection headerText="Ihre Steuerkanzlei in München">
        Als moderne Steuerberatungsgesellschaft liegt unser Fokus auf der
        Erbringung klassischer aber auch innovativer Steuerberatungsleistungen
        für Unternehmen, Freiberufler und Privatpersonen.
      </FirstSection>

      <PageSection grey>
        <ContentDiv>
          <ServicesIndex />
        </ContentDiv>
      </PageSection>
      <PageSection>
        <ContentDiv>
          <SteuernewsIndex />
        </ContentDiv>
      </PageSection>
    </Layout>
  )
}

export default IndexPage
